<template>
  <section class='lvteam'>
    <div class="top">
      <div class="top_wrap">
        <div class="logo">
          <img src="" alt="">
          <div class="title">律梯</div>
        </div>
        <div class="tab">
          <div class="tab_item" :class="{'active_item': index == idx}" v-for="(item, index) in navList" :key="index" @click="goPage(item.url,index)">{{item.title}}
          </div>
        </div>
        <div class="btn_wrap" v-if='!isLogin'>
          <el-button class="btn register_btn" @click="handleReg">注册</el-button>
          <el-button class="btn login_btn" @click="handleLogin">登录</el-button>
        </div>
        <div class="btn_wrap" v-else>
          <el-button class="btn login_btn" @click="enterMain">进入律梯</el-button>
        </div>
      </div>
    </div>
    <div class="nav_container">
      <router-view></router-view>
    </div>
  </section>
</template>

<script>
  import {
    reactive,
    ref
  } from 'vue'
  import {
    useRouter
  } from 'vue-router';

  export default {
    name: 'Lvteam',
    setup() {
      let navList = reactive([{
        title: '首页',
        url: '/home'
      }, {
        title: '价格',
        url: '/lvteam/price'
      }, {
        title: '关于我们',
        url: '/about'
      }, {
        title: '商务合作',
        url: '/cooperation'
      }, {
        title: '功能演示',
        url: '/demonstration'
      }]);
      let idx = ref(1)
      const router = useRouter()

      function goPage(url,index) {
        idx.value = index
        router.push(url) 
      }
      const handleReg = () => {
        router.push('/register')
      }
      const handleLogin = () => {
        router.push('/login')
      }

      return {
        navList,
        goPage,
        idx,
        handleReg,
        handleLogin
      }
    },
    data(){
      return {
        isLogin:false
      }
    },
    mounted(){
      if(sessionStorage.getItem('token')){
        this.isLogin = true
      }else{
        this.isLogin = false
      }
    },
    methods:{
      enterMain(){
        this.$router.push('/')
      }
    }
  }
</script>
<style lang='scss' scoped>
  //@import url(); 引入公共css类
  @import '@/assets/style/comman.scss';

  .lvteam {
    min-height: calc( 100vh - 80px );
    background: #fd8c6c;
    padding-bottom:30px;
    .top {
      height: 80px;
      border-bottom: 2px solid #f2e7e0;
      box-sizing: border-box;

      .top_wrap {
        width: 1200px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
          display: flex;
          align-items: center;
          cursor: pointer;

          img {
            width: 40px;
            height: 40px;
            background: #99f;
          }

          .title {
            font-size: 32px;
            font-weight: 900;
            color: #fff;
            margin-left: 20px;
          }
        }

        .tab {
          display: flex;
          color: #fff;
          font-size: 18px;
          height: 100%;
          align-items: center;

          .tab_item {
            padding: 0 20px;
            height: 100%;
            display: flex;
            align-items: center;
            &.active_item{
              background: url('~@/assets/image/triangle.png') center 72px no-repeat;
              background-size: 15px 9px;
            }
          }
        }

        .btn_wrap {
          display: flex;

          .btn {
            width: 88px;
            height: 43px;
            display: flex;
            font-size: 14px;
            justify-content: center;
            align-items: center;
            padding: 0;
            color: $main-color;
            border: 1px solid $main-color;

            &.register_btn {
              background: #fec89d;
            }

            &.login_btn {
              color: #fff;
              background: $main-color;
            }
          }
        }
      }
    }

    .nav_container {
      width: 1200px;
      // min-height: calc(100vh - 160px);
      margin: 0 auto;
      margin-top:50px
    }
  }
</style>